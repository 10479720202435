<!--
 * @Author: bashuang
 * @Date: 2023-11-10
 * @Description: 汇达频道-文章详情-第一大标题
 * @props: val 文字内容
-->
<template>
  <div class="articleModule-box" v-if="nextLoading">
    <div class="articleModule" :class="[item.type,{'mark':item.mark}]" v-for="(item,index) in articleContent[articleActive]" :key="index">
      <myImgLazy v-if="item.type==='images'" :src="require(`@/assets/img/channel/${item.val}`)" :style="{width: item.minWidth ? item.minWidth : '100%'}"></myImgLazy>
      <!-- <img alt="" :style="{width: minWidth ? '7.00rem' : '100%'}"> -->
      <div v-if="item.type==='typeTexts' && item.isHtml " v-html="$options.filters.sLang(item.val)"></div>
      <div v-if="item.type==='thirdTitle' && item.isHtml " v-html="$options.filters.sLang(item.val)"></div>
      <template v-if="item.type!=='images'&&!item.isHtml">{{ item.val | sLang }}</template>
    </div>
    <div class="footer-line"></div>
    <div class="bottom-warp">
      <span class="naxt-art-btn left" v-if="articleContent.length>articleActive+1" @click="naxtArt">{{ `下一条：${articleContent[articleActive+1][0].val} >>` | sLang }}</span>
      <div class="back-list-btn right" @click="backList">{{ '返回列表' | sLang }}</div>
    </div>
    <img src="@/assets/img/channel/up_top.png" alt="up" class="go-top" v-show="isShowUp" @click="goTop">
  </div>
  
</template>

<script>
import myImgLazy from "@/components/myImgLazy/index.vue"
import articleJs from "@/mixins/article";
export default {
  name: 'articleModule',
  mixins: [articleJs],
  props: {
    articleActive:0, //文章索引
  },
  data() {
    return {
      nextLoading:false, //切换文章加载页
      timer:null, //切换文章加载时间监听
      isShowUp:false, //
    }
  },
  components:{
    myImgLazy
  },
  mounted() {
    this.$store.commit('updateArticleActive', this.articleActive)
    if(this.articleActive>-1) this.nextLoading = true;
    window.addEventListener('scroll', this.scrolling)
  },
  beforeDestroy(){
    window.removeEventListener("scroll", this.scrolling);
  },
  methods:{
    // 监听滚动条，首页显示nav导航栏样式变化
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // 首页滚动条往上200+非首页都是白底导航
      if (scrollTop > 1000) {
        this.isShowUp = true
      } else {
        this.isShowUp = false
      }
    },
    // 回到顶部
    goTop(){
      document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      document.body.scrollTop = 0
    },
    // 返回列表
    backList(){
      document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      document.body.scrollTop = 0
      this.$router.push({ path: '/channel'})
    },
    // 文章点击下一条
    naxtArt(){
      this.nextLoading = false;
      this.$emit('update:articleActive', this.articleActive +1);
      if (this.timer) { // 判断是否已存在定时器
          clearTimeout(this.timer);
          this.timer = null;
      }
      document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      document.body.scrollTop = 0
      this.timer = setTimeout(() => { // 创建定时器，1.4s后图标回归原位置
          this.nextLoading = true;
          clearTimeout(this.timer);
          this.timer = null;
      }, 200);
    }
  }
}
</script>

<style lang='scss' scoped>
.articleModule-box{
  position: relative;
  .articleModule {
    &.fristTitle {
      text-align: center;
      padding: 0.38rem 0 0.10rem;
      font-size: 0.26rem;
      // font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: 600;
      color: #333333;
      line-height: 0.39rem;
    }
    &.newsTime{
      font-size: 0.13rem;
      // font-family: HiraginoSansGB-W3, HiraginoSansGB;
      font-weight: normal;
      color: #333333;
      text-align: center;
      margin-bottom: 0.10rem
    }
    &.divider{
      width: 100%;
      height: 0.01rem;
      background:#BDBDBD;
      margin-bottom: 0.05rem
    }
    &.images {
      width: 100%;
      padding: 0.10rem 0;
      text-align: center;
      img{
        width: 100%;
        margin:0 auto;
      }
    }
    &.secondTitle {
      text-align: center;
      padding:0.30rem 0 0.20rem;
      font-size: 0.21rem;
      color: #333333;
      font-weight: bold;
    }
    &.thirdTitle {
      text-align: left;
      padding:0.15rem 0 0.08rem;
      font-size: 0.18rem;
      color: #333333;
      font-weight: bold;
    }
    &.redTitleCenter{
      text-align: center;
      padding:0.25rem 0 0.10rem;
      font-size: 0.23rem;
      color: #d3342d;
      font-weight: bold;
    }
    &.redTitleLeft{
      text-align: left;
      padding:0.25rem 0 0.10rem;
      font-size: 0.23rem;
      color: #d3342d;
      font-weight: bold;
    }
    &.blueTitle{
      color:#2453d5;
      text-align: center;
      padding:0.15rem 0 0.10rem;
      font-size: 0.18rem;
      font-weight: bold;
    }
    &.typeTexts {
    text-align: left;
    padding:0.05rem 0;
    font-size: 0.15rem;
    color: #353638;
    font-weight: normal;
    line-height: 0.28rem;
    &.mark{
      padding-left: 0.20rem;
      position: relative;
      &::before{
        content: '·';
        display: block;
        color: #174eec;
        font-size: 0.36rem;
        height: 0.38rem;
        float: left;
        position: absolute;
        left: 0;
      }
    }
  }
 
  } 
  .footer-line{
    width: 100%;
    height: 0.01rem;
    background:#BDBDBD;
    margin:0.50rem 0 0.15rem;
  }
  .naxt-art-btn{
    cursor: pointer;
    padding-top:0.06rem;
    font-size: 0.14rem;
    &:hover{
      color: #0066CC;
    }
  }
  .back-list-btn{
    cursor: pointer;
    width: 0.90rem;
    border-radius: 0.24rem;
    border: 0.01rem solid #979797;
    font-size: 0.14rem;
    // font-family: HiraginoSansGB-W3, HiraginoSansGB;
    font-weight: normal;
    color: #333333;
    line-height: 0.14rem;
    padding:0.08rem 0 0.07rem;
    text-align: center;
    &:hover{
      border: 0.01rem solid #0066CC;
      color: #0066CC;
    }
  }
  .go-top{
    position: fixed;
    right: 0.62rem;
    bottom: 3.24rem;
    display: block;
    width: 0.62rem;
    height: 0.62rem;
    z-index: 0.10rem;
    transition: 2s
  }
}

</style>