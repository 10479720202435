<template>
  <div class="channel-box">
    <div class="banner2">{{ '汇达频道' | sLang }}</div>
    <div class="channel-content w1200">
      <articleModule :articleActive.sync="articleActive">
      </articleModule>

    </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import '../../assets/css/channel/index.scss'
import myFoot from '@/components/myFoot/index.vue'
import articleModule from "./components/articleModule.vue";
import articleJs from "@/mixins/article";
export default {
  name: 'channel',
  data() {
    return {
      articleActive: this.$route.query.articleId*1, // 文章显示内容在mixin articleJs名字
    }
  },
  mixins: [articleJs],
  components: {
    myFoot,
    articleModule
  },

  methods: {

    changePage(i) {
      this.$store.commit('changePage', i)
    },
    
    
  },
}
</script>
